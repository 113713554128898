<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'

import { useBookingWidgetStore } from '@playa/store/bookingWidgetStore'

const props = defineProps<{
  special: SpecialInterface
  index: number
}>()

const bookingWidgetStore = useBookingWidgetStore()

const instanceId = uuidv4()
const { toggleBookingModal } = bookingWidgetStore

const colors = [
  'bg-midnight',
  'bg-shocking',
  'bg-mauve',
  'bg-silvertree',
  'bg-ochre',
]

// text-shocking wasn't working so doing this for now
const btnColors = [
  '#002A3A',
  '#DF4D2C',
  '#914669',
  '#64A8A7',
  '#CA9117',
]

const showTerms = ref(false)

const currentButtonColor = computed(() => {
  return btnColors[props.index % btnColors.length]
})
</script>

<template>
  <div class="slide grid sm:grid-cols-12 text-white overflow-hidden" :class="[colors[index % colors.length]]">
    <div class="row-start-2 sm:row-start-auto flex flex-col justify-between sm:col-span-4 px-8 py-6 space-y-2">
      <div>
        <div class="text-2xs font-sandpiper-display font-medium uppercase">
          {{ special.subtitle }}
        </div>
        <div class="text-xl font-sandpiper-display font-medium">
          {{ special.title }}
        </div>
        <div class="mt-4 text-xs text-white" v-html="special.content" />
      </div>
      <div class="mb-2 pt-4">
        <button
          type="button"
          :style="{ color: currentButtonColor }"
          class="text-xs font-sandpiper-display uppercase font-bold bg-white px-6 py-2.5 rounded"
          @click.prevent="toggleBookingModal(instanceId)"
        >
          Book Now
        </button>
        <div class="mt-8 text-xs">
          Offer Expires: {{ special.end_date }}
        </div>

        <div
          class="mt-2 text-xs cursor-pointer font-bold"
          @click="showTerms = true"
        >
          Terms & Conditions
        </div>
      </div>
    </div>
    <div class="row-start-1 sm:row-start-auto sm:col-span-8 aspect-[4/3]">
      <img :src="special.image" class="w-full h-full object-cover">
    </div>

    <Teleport to="#portal-destination">
      <div v-if="showTerms" class="fixed inset-0" style="z-index: 99999;">
        <div class="z-40 bg-black opacity-75 fixed inset-0 cursor-pointer" />
        <svg class="w-6 h-6 absolute z-50 top-0 right-0 mt-8 mr-8 text-white cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="showTerms = false">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <div class="p-8 fixed z-50 rounded shadow-lg overflow-y-scroll bg-white" style="left: 50%; top: 50%; transform: translate(-50%, -50%); height: 80%; width: 70%;">
          <h3 class="text-xl font-bold">
            Terms &amp; Conditions
          </h3>
          <div class="pt-8">
            {{ special.terms }}
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>

</style>
